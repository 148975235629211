'use client'
import styles from '@/app/ui/home/home.module.css'
import Builders from '@/app/ui/icons/builders.svg'
import Optimists from '@/app/ui/icons/optimists.svg'
import Trailblazers from '@/app/ui/icons/trailblazers.svg'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { Button } from '@/app/ui/common/button'

function LandingBlockA() {
  return (
    <div className={styles.block}>
      <div className={styles.title}>Who We Are</div>
      <div className={styles.copy}>
        The NFTfi Foundation is a non-profit organization with the mandate to promote healthy NFT credit markets. It will steward the NFTfi protocol and grow the NFTfi ecosystem
        until they become self-sufficient.
      </div>
    </div>
  )
}

function LandingBlockB() {
  return (
    <div className={styles.block}>
      <div className={styles.title}>Our Vision</div>
      <div className={styles.copy}>
        NFTs are becoming a core building block of the web. The credit markets surrounding NFTs will soon support millions of users. We are creating the leading decentralized
        protocol enabling these markets.
      </div>
    </div>
  )
}

function LandingBlockC() {
  return (
    <div className={styles.block}>
      <div className={styles.title}>Our Mission</div>
      <div className={styles.copy}>
        NFTs are a technological medium representing a new paradigm of ownership, internet culture, and creator economies. Our mission is to enable NFT owners the financial
        flexibility they deserve.
      </div>
    </div>
  )
}

export default function Home() {
  return (
    <>
      <div className={styles.introSection}>
        <div className={styles.leader}>
          <div>
            Advancing NFT
            <br /> Finance, together.
          </div>
          <span>
            The NFTFI airdrop claim is
            <br /> now live!
          </span>
          <Button markup="anchor" to="/check" className="h-[36px] sm:h-[53px] w-full sm:w-[240px] text-sm leading-3 sm:text-lg">
            Claim airdrop
          </Button>
        </div>

        <div className={`${styles.blocks} ${styles.blocksDecorated} ${styles.blocksMobile}`}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={false}
            showThumbs={false}
            useKeyboardArrows={true}
            autoPlay={false}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={false}
            emulateTouch={true}
            autoFocus={true}
            thumbWidth={100}
            swipeScrollTolerance={5}
            centerMode={true}
            centerSlidePercentage={100}
          >
            <LandingBlockA />
            <LandingBlockB />
            <LandingBlockC />
          </Carousel>
        </div>
        <div className={`${styles.blocks} ${styles.blocksDecorated} ${styles.blocksDesktop}`}>
          <LandingBlockA />
          <LandingBlockB />
          <LandingBlockC />
        </div>
      </div>

      <div className={styles.metricHighlight}>
        <div>$530,000,000+</div>
        <div>of loans settled across 61,000+ loans</div>
      </div>

      <div className={styles.blocksHeading}>Our values</div>
      <div className={`${styles.blocks}`}>
        <div className={styles.block}>
          <div className={styles.icon}>
            <Optimists />
          </div>
          <div className={styles.title}>Optimists</div>
          <div className={styles.copy}>We believe NFTs will be omnipresent and used by billions of people.</div>
        </div>
        <div className={styles.block}>
          <div className={styles.icon}>
            <Trailblazers />
          </div>
          <div className={styles.title}>Trailblazers</div>
          <div className={styles.copy}>We invented NFT lending. Here from the start, and for the future.</div>
        </div>
        <div className={styles.block}>
          <div className={styles.icon}>
            <Builders />
          </div>
          <div className={styles.title}>Builders</div>
          <div className={styles.copy}>We are charting new technical territory for digital asset finance.</div>
        </div>
      </div>
      <div className={styles.spacer}></div>
    </>
  )
}
