import clsx from 'clsx';
import Link from 'next/link';
import styles from '@/app/ui/common/button.module.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  mode?: 'primary' | 'secondary' | 'tertiary';
  markup?: 'button' | 'anchor';
  to?: string;
}

export function Button({ children, className, disabled = false, markup = 'button', mode = 'primary', to = '', id, ...rest }: ButtonProps) {
  const isAnchor = markup === 'anchor';
  const isPrimary = mode === 'primary';
  const isSecondary = mode === 'secondary';
  const classes = clsx(
    [
      `p-3 rounded-md`,
      className,
      isAnchor ? styles.buttonTypeLink : '',

      isPrimary ? styles.primaryButton : '',
      isPrimary && disabled && isAnchor ? styles.primaryButtonDisabled : '',

      isSecondary ? styles.secondaryButton : '',
      isSecondary && disabled && isAnchor ? styles.secondaryButtonDisabled : '',
    ].join(' ')
  );

  return isAnchor ? (
    <Link href={to} className={classes} id={id}>
      {children}
    </Link>
  ) : (
    <button {...rest} id={id} disabled={disabled} className={classes}>
      {children}
    </button>
  );
}
